import Logger from "js-logger";
import {
  hideNextContentBox,
  nextContentLoadImage,
  setNextContentData,
  startNextContent,
} from "../actions/nextContent";
import { createLoadRequestData, getImageProxyUrl } from "./util";

import pkg from "../../package.json";
import { mediaEnded } from "../actions";
import { config } from "../conf";
import type { RootState } from "../redux-store";

const logger = Logger.get("NextContent");

export class NextContent {
  private dispatch: any;
  private getState: () => RootState;

  constructor(dispatch: any, getState: () => RootState) {
    this.dispatch = dispatch;
    this.getState = getState;
  }

  public async fetchNextContent(): Promise<void> {
    const { content, user } = this.getState();

    const { metadata, trackingData } = content?.asset || {};
    const assetId = metadata?.id;
    const gaTrackingId = trackingData?.GA?.id;

    if (!assetId) {
      logger.info("Asset id is empty, skipping endscreen request");
      this.dispatch(setNextContentData(null));
      return;
    }

    // gaTrackingId should be the same as the asset id, unless it's a vimond id.
    // We cannot use vimond ids for the gateway endpoint.
    if (gaTrackingId && assetId !== gaTrackingId) {
      logger.info(
        `Asset id "${assetId}" seems to be a vimond id, skipping endscreen request`,
      );
      this.dispatch(setNextContentData(null));
      return;
    }

    if (metadata.type !== "episode") {
      logger.info("Asset is not an episode, skipping endscreen request");
      this.dispatch(setNextContentData(null));
      return;
    }

    // For specs, see Apollo (requires access or the the gateway-services schema
    // https://github.com/TV4/gateway-services/blob/45cfedbf99c3ef1df8d54116fcac9042866bac4b/schema.graphql#L1229
    // We really should load this at "endScreenLoadThreshold", but we don't use graphql to load the asset data initially, so we don't even have that
    const query = {
      query: `
      query Endscreen($assetId: ID!) {
        endScreen(id: $assetId) {
          nextContent {
            ... on Episode {
              id
              title
              access  {
                hasAccess
              }
              images {
                main16x9 {
                  sourceEncoded
                }
              }
            }
          }
        }
      }`,
      variables: { assetId },
    };

    try {
      const { graphqlEndpoint } = config;
      const response = await fetch(graphqlEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Client-Name": "avod-chromecast",
          "Client-Version": pkg.version,
          ...(user.authorizationToken && {
            Authorization: `Bearer ${user.authorizationToken}`,
          }),
        },
        body: JSON.stringify(query),
      });
      const responseData = await response.json();

      const nextContent = responseData?.data?.endScreen?.nextContent;

      // reset next content if there is no next content with access
      // if there is no next content, then nextContent can be either `{}` or null
      if (!nextContent?.id || !nextContent?.access?.hasAccess) {
        this.dispatch(setNextContentData(null));
        return;
      }

      this.dispatch(setNextContentData(nextContent));

      const imageUrl = nextContent?.images?.main16x9?.sourceEncoded;
      const proxiedImageUrl = imageUrl && getImageProxyUrl(imageUrl, 600);

      if (proxiedImageUrl) {
        this.dispatch(nextContentLoadImage(proxiedImageUrl));
      }
    } catch (error) {
      logger.error(error);
    }
  }

  public start(assetId: string) {
    this.getState().api.trackingService?.stop();
    this.dispatch(hideNextContentBox());
    this.dispatch(mediaEnded());
    const loadRequestData = createLoadRequestData(assetId, false);
    const context = cast.framework.CastReceiverContext.getInstance();
    const playerManager = context.getPlayerManager();
    playerManager.load(loadRequestData);
    this.dispatch(startNextContent());
  }
}
